import '@/assets/scss/sweetalerts.scss'
import Swal from 'sweetalert2'
import router from '@/router'

export const modalGenericModel = (
    title,
    message,
    icon,
    textButtonConfirm,
    textButtonCancel = null,
    allowClickOutside = false
  ) => Swal.fire(
    {
      width: 550,
      imageUrl: icon,
      imageWidth: 80,
      imageHeight: 80,
      title: title,
      html: message,
      showCancelButton: textButtonCancel != null,
      confirmButtonText: textButtonConfirm,
      cancelButtonText: textButtonCancel,
      reverseButtons: true,
      allowOutsideClick: allowClickOutside,
      customClass: {
        confirmButton: 'btn btn-primary mb-1',
        cancelButton: "btn btn-outline-secondary mr-1 mb-1"
      },
      buttonsStyling: false
    }
  )

export const modalGenericFillableErrorWithReload = async (message) => {
  await modalGenericModel(
    'Erro',
    message,
    require('@/assets/custom-icons/cora-icons/error.png'),
    'Ok'
  ).then(() => {
    window.location.reload()
  })
}

export const modalGenericFillableErrorWithRedirectHome = async (message) => {
  await modalGenericModel(
    'Erro',
    message,
    require('@/assets/custom-icons/cora-icons/error.png'),
    'Ok'
  ).then(() => {
    router.push(
      {
        name: 'bem-vindo'
      }
    );
  })
}

export const modalGenericErrorWithRedirectHome = () => {
  modalGenericModel(
    'Ocorreu um erro!',
    'Ocorreu um erro de conexão com nossa base de dados. Por favor, tente novamente mais tarde ou contate nosso suporte.',
    require('@/assets/custom-icons/cora-icons/error.png'),
    'Ok'
  ).then(() => {
    router.push(
      {
        name: 'bem-vindo'
      }
    );
  });
}
